@import "../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../node_modules/ag-grid-community/dist/styles/ag-theme-material.css";

@import "variables";
@import "typography";

.success-snackbar {
  color: #0e7537;
  background: #d6fae4;
  border: 1px solid #0e7537;
}

.success-snackbar button {
  color: #000;
  background: #d6fae4;
}

.error-snackbar {
  color: #f00;
  background: #FFF5F5;
  border: 1px solid #f00;
}

.error-snackbar button {
  color: #000;
  background: #FFF5F5;
}

.mat-input-element:disabled {
  color: rgba($black-color, 0.4) !important;
}

.mat-input-element::placeholder {
  color: rgba($black-color, 0.4) !important;
}

.ng-valid {
  color: $black-color !important;
}

.ag-theme-material {
  font-family: 'Poppins';
  border-radius: 6px;
  color: $black-color;

  .ag-menu-option-active {
    background-color: $primary-color;
    color: $white-color;
  }

  .ag-root-wrapper-body {
    background-color: $white-color;
  }

  .ag-checkbox-input-wrapper {

    &.ag-indeterminate:after,
    &.ag-checked::after {
      color: $primary-color;
    }
  }

  .ag-checkbox-input-wrapper {
    border-radius: 6px;
  }

  .ag-checkbox-input-wrapper::after {
    color: $black-color;
  }

  .ag-pinned-left-header {
    border-right: none;
    background-color: $white-color;
  }

  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
    border-right: none;
  }

  .ag-row {
    background-color: $white-color;
  }

  .ag-row-selected {
    background-color: $selected-color;
  }

  .ag-row-hover {
    background-color: $hover-color;
  }

  .ag-header-viewport {
    background-color: $white-color;
  }

  .ag-header-cell {
    background-color: $white-color;

    .ag-header-cell-label {
      text-align: left;
    }
  }

  .ag-header-cell,
  .ag-cell {
    padding: 0 10px;
    font-size: 15px;
  }

  .ag-row {
    color: $black-color;
  }

  .ag-row,
  .ag-header {
    line-height: 65px;
  }

  .ag-header {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
  }

  .ag-cell {
    display: flex;
    align-items: center;
    border: none;
  }

  .ag-ltr .ag-has-focus .ag-cell-focus,
  .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
    border: none;
    outline: none;
  }

  ag-grid-angular {
    height: 100%;
    width: 100%;
  }

  .ag-header-cell-text {
    color: $black-color;
  }

  .ag-header-cell-label {
    font-weight: $font-weight-semibold;
    cursor: pointer;
  }

  .ag-group-contracted,
  .ag-group-expanded {
    padding: 15px;
    cursor: pointer;
  }

  .ag-horizontal-left-spacer {
    overflow-x: auto;
  }
}

.joyride-button {
  font-weight: 400;
  border-radius: 20rem;
}

.joyride-step__container {
  border-radius: 6px;
  min-width: 250px;
}

.joyride-step__title {
  color: $primary-color !important;
}

.joyride-button {
  background: $primary-color !important;

  &:hover {
    border-color: $primary-color !important;
    color: $primary-color !important;
    background: $white-color !important;
  }
}

#backdrop-ResultsGroupStep .backdrop-middle-container,
#backdrop-GroupStatusStep .backdrop-middle-container,
#backdrop-SelectGroupStep .backdrop-middle-container,
#backdrop-UserStatusStep .backdrop-middle-container,
#backdrop-SelectUserStep .backdrop-middle-container,
#backdrop-SelectUsersStep .backdrop-middle-container {
  height: 60px !important;
}

.select-users-joyride {
  position: absolute;
  left: 0;
  top: 0;
  width: 150px;
}

.select-group-joyride,
.select-user-joyride {
  position: absolute;
  left: 0;
  top: 65px;
  width: 150px;
}

.group-status-joyride,
.user-status-joyride {
  position: absolute;
  right: 30px;
  top: 70px;
  width: 140px;
}

.group-status-joyride {
  position: absolute;
  right: 25px;
  top: 70px;
  width: 70px;
}

.mat-dialog-actions,
.mat-dialog-content {
  margin: 0 !important;
}



.mat-tab-header {
  margin-bottom: 1rem;
}

mat-expansion-panel-header {
  .mat-content {
    align-items: center;
  }
}

.tooltip-guide-nav,
.tooltip-guide {
  &>.text-block {
    display: none;
    align-items: center;
    padding: 5px;
    text-align: center;
    position: fixed;
    z-index: 100;
    right: 20px;
    background: $white-color;
    width: 150px;
    height: 50px;
    color: $black-color;
    font-size: 0.825rem;
    border-radius: 6px;
  }
}

.tooltip-guide-nav {
  &>.text-block {
    right: auto;
    left: 210px;
    bottom: 48px;
  }
}

.tooltip-guide-nav.animate,
.tooltip-guide.animate {
  mat-icon {
    -webkit-animation: pulse 3s infinite ease-in-out;
    -o-animation: pulse 3s infinite ease-in-out;
    -ms-animation: pulse 3s infinite ease-in-out;
    -moz-animation: pulse 3s infinite ease-in-out;
    animation: pulse 3s infinite ease-in-out;
  }

  &>.text-block {
    display: flex;
  }
}

.logged-in-user {
  .mat-button-wrapper {
    display: flex;
    max-width: 200px;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.9);
    opacity: 0.7;
  }

  50% {
    -webkit-transform: scale(1.3);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(0.9);
    opacity: 0.7;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 0.7;
  }

  50% {
    transform: scale(1.3);
    opacity: 1;
  }

  100% {
    transform: scale(0.9);
    opacity: 0.7;
  }
}