@import "~@angular/material/theming";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import "typography";
@import "variables";

// Plus imports for other components in your app.
@import "~@ng-select/ng-select/themes/material.theme.css";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.

$orange-palette: (
  50: #fff3e0,
  100: #ffe0b2,
  200: #ffcc80,
  300: #ffb74d,
  400: #ffa726,
  500: #ff9800,
  600: #fb8c00,
  700: #f57c00,
  800: #ef6c00,
  900: $primary-color,
  A100: #ffd180,
  A200: #ffab40,
  A400: #ff9100,
  A700: #ff6d00,
  contrast: (50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: $black-87-opacity,
    600: $black-87-opacity,
    700: $black-87-opacity,
    800: $white-color,
    900: $white-color,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: black,
  ),
);

$mat-grey: (
  0: #ffffff,
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #29292d,
  800: #3a3a40,
  900: #212121,
  1000: #000000,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (0: $black-87-opacity,
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: $black-87-opacity,
    600: $white-87-opacity,
    700: $white-87-opacity,
    800: $white-87-opacity,
    900: $white-87-opacity,
    1000: $white-87-opacity,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: $white-87-opacity,
  ),
);

$pits-app-primary: mat-palette($orange-palette, 900);
$pits-app-accent: mat-palette($mat-grey);

// The warn palette is optional (defaults to red).
$pits-app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$pits-app-theme: mat-light-theme($pits-app-primary,
    $pits-app-accent,
    $pits-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($pits-app-theme);

$mat-font-family: "Poppins";

* {
  box-sizing: border-box;
}

html,
body,
app-root {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: $mat-font-family;

  .mat-expansion-panel-content,
  .mat-tab-group,
  .mat-button-toggle,
  .mat-button-base,
  .mat-button,
  .mat-flat-button,
  .mat-fab,
  .mat-icon-button,
  .mat-mini-fab,
  .mat-card,
  .mat-checkbox,
  .mat-form-field,
  .mat-list,
  .mat-menu-item,
  .mat-radio-button,
  .mat-select,
  .mat-list .mat-list-item .mat-list-item-content,
  .mat-nav-list .mat-list-item .mat-list-item-content,
  .mat-simple-snackbar,
  .mat-tab-label,
  .mat-slide-toggle-content,
  .mat-toolbar,
  .mat-tooltip,
  h4,
  h3,
  h2,
  h1 {
    font-family: $mat-font-family;
  }
}

.icon-clear {
  color: $black-color;
}

.tooltip-guide {
  top: 18px !important;
  color: $black-color;
}

button.mat-button:not(.medium-button, .mat-basic-button),
button.mat-flat-button {
  height: 46px;
  line-height: 46px;
  border-radius: 20rem;
  font-size: 11px;
  transition: ease-in-out 0.3S;
  display: flex;
  justify-content: center;
  align-items: center;


  &:hover {
    background-color: $primary-hover;
    color: $white-txt;
  }
}

.mat-menu-item {
  color: $black-color;
}

button {
  transition: ease-in-out 0.3s;
  cursor: pointer;

}

button.mat-flat-button {
  text-transform: uppercase;
  align-items: center !important;
}

.mat-button-disabled {
  opacity: 0.2;
  background-color: none;

}

.mat-flat-button {
  background-color: transparent;
}

.mat-flat-button.mat-primary {
  color: $white-txt;
}

.mat-flat-button.mat-primary.mat-button-disabled {
  background-color: $primary-color;
  color: $white-txt;

  &:hover {
    background-color: $primary-hover;
    color: $white-txt;
  }
}



.mat-secondary {
  border: 2px solid $primary-color !important;
  transition: ease-in-out 0.3s;
  color: $primary-color !important;

  &:hover {
    border: 2px solid $primary-hover !important;
    color: $white-txt !important;

  }
}


.mat-tab-group {
  .mat-tab-labels {
    .mat-tab-label {
      font-size: 22px;
      font-weight: $font-weight-regular;
      height: 60px;
      line-height: 60px;
    }
  }
}


.mat-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-row {
  font-size: 14px;

  .mat-form-field {
    line-height: 16px;
  }
}

.ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
  color: $primary-color;
}

.ng-select {
  &-container:after {
    border-bottom: 1px solid $black-color !important;
  }
}

.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
  color: $primary-color !important;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: $black-color;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  color: $black-color;
}

.ng-select.ng-select-focused .ng-select-container:after {
  border-color: $primary-color;
}

.mat-form-field-label-wrapper .mat-form-field-label {
  font-size: 15px;
  line-height: 20px;
}

h4 {
  font-size: $font-size-h4;
  font-weight: $font-weight-regular;
  margin-top: 0;
  font-family: $mat-font-family;
}

h5 {
  font-size: $font-size-h5;
}

.mat-tab-body-wrapper {
  height: 100%;
}

.mat-button {
  transition: 0.3s;

  &.mat-secondary,
  &.mat-primary {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: $font-weight-semibold;
  }

}



.mat-option.mat-active {
  background-color: $selected-color !important;
  color: $primary-color;
}

.mat-option {
  color: $black-color;
  background-color: $white-color;

  &:hover {
    background-color: $selected-color;
  }
}

.mat-list-base .mat-list-item .mat-list-text,
.mat-list-base .mat-list-option .mat-list-text {
  color: $black-color;
}

app-navigation {
  .mat-list-item {
    &.active {
      #navigation-icon {
        fill: $primary-color;
      }

      #learning-path-icon path {
        stroke: $primary-color;
      }
    }
  }
}

*:focus {
  outline: none;
}

.mat-expansion-panel {
  background-color: $white-color;
}

.mat-tab-label {
  color: $black-color;
}

.mat-accordion {
  .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: 0 0 0 -2px rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14),
      0 0 0 0 rgba(0, 0, 0, 0.12);
  }

  .mat-expansion-panel-spacing {
    margin: 0;
  }

  .mat-expansion-panel-header {
    padding: 0;
    background: $white-color;
    height: 48px !important;

    .accordion-title {
      height: 48px;
      line-height: 48px;
    }

    .accordion-end {
      line-height: 48px;
    }

    mat-slide-toggle {
      display: inline-block;
      height: 64px;
      margin-right: 13px;
    }
  }

  .mat-list {
    padding-top: 0;
    margin-bottom: 20px;

    .mat-list-item {
      border-top: 1px solid $border-color !important;
      background-color: $white-color;
      margin-left: 10px;
      width: auto;
      height: initial;

      .mat-list-item-content {
        align-items: center;
        background-color: $white-color !important;

        .mat-list-text {
          padding: 5px 0;
        }
      }

      .mat-checkbox {
        margin-right: 10px;
      }
    }
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}

.mat-expansion-panel {
  background-color: $white-color;
}

.mat-list-item-content {
  background-color: $white-color;
}

.mat-list-item-content:first-child(even) {
  background-color: $cell-color;
}

accordion-toggle {
  margin-left: 30px;
  margin-right: 23px;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
}

mat-list-item-content {
  align-items: center;
}

.mat-checkbox-frame {
  border-color: $black-color;
}

.mat-form-field-underline {
  background-color: $black-color !important;
}

.mat-checkbox-frame {
  border-color: $black-color;
}

.fa-angle-down {
  display: none !important;
}

.fa .fa-angle-up {
  display: none !important;
}

.mat-dialog-container {
  position: relative;
  color: $black-color;

  .close-button:not(.medium-button) {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.mat-tab-header {
  line-height: 16px;
  border-bottom: 2px solid $border-color;
}

.mat-tab-label-content {
  color: $black-color;
  z-index: 99;
}

.mat-drawer-side {
  border: none;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $primary-color;
  border-radius: 1rem;
}

.mat-drawer-container {
  background-color: $background-color;
  z-index: -1;
  color: $black-color;
}

.list-content {
  border-radius: 0 0 12px 12px;
  overflow: hidden;

  mat-list {
    height: 100%;

    virtual-scroll {
      max-height: 100%;
    }
  }
}

.select-icon {
  margin-left: 6px;
  margin-bottom: 2px;
  width: 50%;
}

.ag-body-viewport {
  overflow-x: hidden;
}

.invited {
  background-color: #ff9308;
}

.problem {
  background-color: #ff4e50;
}

.deactivated {
  background-color: #9c9c9c;
}

.accepted {
  background-color: #ffda02;
}

.activated {
  background-color: #7ebd3a;
}

.playing {
  background-color: #bf93ff;
}

.pits-loading {
  height: 100%;
  background-image: url("../assets/img/background.png");
  background-size: cover;

  img {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.selection-indication {
  color: $not-active-course-color;
  font-size: 15px;
}

div.mat-slide-toggle-bar {
  height: 2px;

  .mat-slide-toggle-thumb-container {
    top: -9px;
  }
}


.mat-slide-toggle.mat-checked:not(.mat-disabled) {
  .mat-slide-toggle-thumb {
    box-shadow: 0 0 9px 2px rgba($primary-color, 0.23);
  }
}

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: $primary-color;
}

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: $primary-hover;
}

.report-content {
  height: 99.5% !important;
}

.upgrade-content {
  height: 100%;
}

iframe {
  border: none;
}

.hint {
  color: $hint-color;
}

h4 {
  font-size: 18px;
  color: $black-color;
}

h3 {
  font-size: 24px;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: $black-color;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-regular;
}

.add-user-dialog,
.confirmation-dialog {
  max-height: 90vh;
  width: 506px;

  .mat-dialog-container {
    display: flex;
    overflow: unset;
    padding-top: 0;

    header {
      min-height: 68px;
    }

    footer {
      margin-top: 24px;
    }
  }

  @media screen and (max-width: 800px) {
    max-width: 100vw !important;
  }
}

.listspinner {
  margin: 10px auto;
  width: 24px;
  height: 24px;
  color: $black-color;
}

.notifications-modal__panel .mat-dialog-container {
  background: $hint-color;
}

.pits-toast.pits-toast {

  &-success,
  &-error {
    border-radius: 6px;
  }
}

.pits-toast {

  &-success {
    background: #e3fec6;
    border: 2px solid #7ebd3a;
  }

  &-error {
    background: #ffcaca;
    border: 2px solid #e02020;
  }

  span {
    font-size: 15px;
    font-weight: 600;
    color: #282c33;
    word-break: break-word;
  }
}

// TODO: make decomposition in assets by pages
.nav-expand .mat-list-item-content {
  background-color: $selected-color;
}

.header-less-tabs .mat-tab-group .mat-tab-header {
  display: none;
}

.header-less-tabs {
  width: 100%;
  height: 100%;
}

input.ng-pristine {
  color: $black-color;
}

.mat-menu-panel.learning-path-item__menu,
.mat-menu-panel.learning-module-item__menu {
  min-height: auto;
  padding: 4px;

  & .mat-menu-content {
    padding: 0;
  }
}

.mat-expansion-panel.learning-path-item__panel .mat-expansion-panel-body,
.mat-expansion-panel.available-courses-panel .mat-expansion-panel-body {
  padding: 0;
}

.learning-module-panel .mat-expansion-panel-body,
.learning-module-translations .mat-expansion-panel-body,
.learning-module-create .mat-expansion-panel-body {
  padding: 24px 0 0;
}


.ng-dropdown-panel.lmc-form__courses,
.ng-dropdown-panel.lmt__select-language,
.ng-dropdown-panel.lp-form__language,
.ng-dropdown-panel.course-form__select,
.ng-dropdown-panel.cit__select-language {
  .ng-option.ng-option-selected {
    color: $primary-color;
  }
}

.mat-list-base.learning-module-list .learning-module-list__item .mat-list-item-content,
.mat-list-base.course-list .course-item .mat-list-item-content,
.learning-module-list__item .mat-list-item-content {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  width: 100%;
}

.courses-dialog__tabs.mat-tab-group .mat-tab-labels .mat-tab-label {
  font-size: 18px;
  line-height: 20px;
  color: $black-color;
}

.dashboard-container {
  height: 100%;
}

.report-container {
  height: 100%;

}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: $background-color;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba($black-color, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba($black-color, 0.6);
}

.hidden {
  height: 0 !important;
  width: 0 !important;
}

.license-table__search .mat-form-field-wrapper .mat-form-field-flex {
  align-items: flex-end;

  mat-icon {
    margin-bottom: 3px;
  }
}

.joyride-step__container {
  box-shadow: 0px 11px 15px -7px rgba(19, 23, 44, 0.50) !important;
}

.mat-drawer-inner-container {
  overflow: overlay !important;
}

.mat-drawer-inner-container::-webkit-scrollbar {
  display: none;
}

.mat-horizontal-stepper-header-container {
  background-color: $unselected-color;
  border-radius: 32px;
  width: 100%;
  margin-bottom: 10px;
}

.mat-step-header[aria-selected="true"] {
  background-color: $selected-color;
  border-radius: 32px;
  width: 100%;

  & div.mat-step-label.mat-step-label-active.mat-step-label-selected {
    color: $white-color;
  }
}

.mat-step-header {
  background-color: $unselected-color;
  border-radius: 32px;
  width: 100%;

  & div.mat-step-label {
    color: $black-color;
    font-weight: 400;
    font-size: 22px;
  }
}

.mat-horizontal-stepper-header {
  pointer-events: none !important;
}