$primary-color: #FF9900;
$primary-hover: #D58001;
$accent-color: #00A0DE;
$accent-hover: #04739F;
$secondary-color: #fff;
$border-color: #d1dde4;
$white-color: #fefefe;
$black-color: #334754;
$error-color: #f44336;
$hint-color: #D3E1E9;
$line-color: #D3E1E9;
$background-color: #F1F4F5;
$selected-color: rgba(210, 224, 232, 0.8);
$hover-color: rgba(210, 224, 232, 0.4);
$button-disabled: #D2E0E8;
$black-opacity: rgba(51, 71, 84, 0.75);
$white-txt: #FFF;
$cell-color: rgba($border-color, 0.3);
$silver-star-color: #d3d3d3;
$bronze-star-color: #cd7f32;
$gold-star-color: #ffd700;
$unselected-color: #F3F3F3;
$warning-background-color: #ffc000;
$error-background-color: #ff4e50;

$course-completed-background-color: #7ebd3a;
$course-in-progress-background-color: $warning-background-color;
$course-not-played-background-color: $error-background-color;
$course-expired-background-color: #8d8d8e;

$list-item-even-background-color: #fafafa;
$not-active-course-color: #c1c7d4;

$remove-background: #fff;
$list-item-text-color: #D3E1E9;

$side-navigation-background-color: $white-color;

:root {
  --global-content-padding: 30px 10px 10px 10px;
  --powerbi-color-override: rgba(51, 71, 84, 0) !important;
}